$color-1: #6A7B95;
$color-2: #393B47;
$color-3: #393B47;
$color-4: #D3D3D3;
$color-5: #393B47;
$color-6: #6A7B95;
$color-7: #6A7B95;
$color-8: #D3D3D3;
$color-9: transparent;
$color-10: #FFFFFF;
$color-11: #F8F8F8;
$color-12: #ECECEC;
$color-13: #D3D3D3;
$color-14: #A2A2A2;
$color-15: #676767;
$color-16: #404040;
$color-17: #212121;
$color-18: #FFFFFF;
